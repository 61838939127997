<!--
 * @Author: HZH
 * @Date: 2021-09-16 14:16:01
 * @LastEditors: HZH
 * @LastEditTime: 2021-09-18 09:37:26
 * @Description: 
-->
<template>
	<div>
		<div class="home">
			<!-- <Head></Head> -->
			<div class="content">
				<div class="wrapper">
					<div class="product_title">服务协议</div>
					<div>
						<div style="font-size:13px; color: #333; line-height: 22px;word-wrap:break-word; ">
						中爱一生服务协议<br>
						欢迎您（下称“您”或“用户”）与中爱一生网络科技(成都)有限公司（下称“中爱一生网络科技(成都)有限公司”）共同签署订立《中爱一生用户注册和版权协议》（以下称“本协议”）并使用中爱一生APP/小程序/网页（下称“中爱一生平台”）服务。 注意：【审慎阅读】本服务协议具有合同效力，请您务必审慎阅读、充分理解本协议各条款内容，特别是免除或者限制责任的条款、法律适用和争议解决条款等重要条款。本协议的重要条款均以粗体标识，请您重点阅读。如您对协议有任何中爱一生网络科技(成都)有限公司米哆电子商务有限公司客服咨询。用户点击同意并接受本协议的，即视为用户确认自己具有订立本协议的相应的权利能力和行为能力，能够独立承担法律责任。 您有违反中爱一生网络科技(成都)有限公司时，中爱一生网络科技(成都)有限公司有权依照本协议根据您违反本协议的情况随时单方采取限制、中止或终止向您提供服务等措施，并有权追究您的相关责任。<br>
						一、用户注册<br>
						1.1 您可以通过中爱一生平台注册服务账号并设置相应的登录密码；用户完成注册申请手续后，将获得中爱一生平台服务账号的使用权。当您注册时应当按注册页面要求亲自填写您的真实信息及其他必要的信息以完成注册，不得采用注册机或者其他类似的自动化方式注册账户。<br>
						1.2 用户应妥善保管其中爱一生平台用户名及密码信息等，未经中爱一生网络科技(成都)有限公司同意，不得向他人转让或授权他人使用。您应使用真实、准确的手机号、微信账号等联系信息与登录账号进行关联，并在前述联系方式有变动时及时中爱一生网络科技(成都)有限公司米哆电子商务有限公司小程序/APP/网页完成的操作、行为或承诺均视为您本人的行为或已获得您充分的授权。<br>
						1.3 用户同意，中爱一生平台有权使用用户的注册信息、用户名、密码等信息，登录进入用户的注册账户，进行证据保全，包括但不限于公证、见证等。<br>
						二、隐私政策<br>
						2.1 用户注册时，至少需向中爱一生平台提供准备使用的账户名、密码、本人的手机号码、电子邮箱地址等（用于验证用户信息），中爱一生平台将通过发送短信验证码或邮件的方式来验证用户身份是否有效。<br>
						2.2用户注册并使用中爱一生平台，视为授权中爱一生平台搜集用户姓名、地址、手机号码、银行卡号等个人信息，并向依法经营的第三方征信机构、金融机构查询、验证中爱一生平台用户提供的个人信息的真实性。用户个人信息如有变动的，应及时更新个人信息。中爱一生平台应在合法必要的范围内，为进行交易及提供中爱一生平台服务为目的使用.<br>
						</div>
					</div>
					<!-- <div class="footer">
						<Foot></Foot>
					</div> -->
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		get
	} from "../utils/request";
	import Head from './components/Head.vue'
	import Foot from './components/Foot.vue'
	export default {
		// 使用注册组件
		components: {
			// 1.引入所需组件

			// 2.注册组件
			Head,
			Foot
		},
		data() {
			return {
				// message: "hello",
				swipe: [{
						"id": 1,
						"url": "https://zhongaiyisheng.oss-cn-beijing.aliyuncs.com/uploads/images/202307101021007d3305321.png",
					},
					{
						"id": 1,
						"url": "https://zhongaiyisheng.oss-cn-beijing.aliyuncs.com/uploads/images/202307101021007d3305321.png",
					}
				],

			};
		},
		methods: {

		},
		created() {

		},
	};
</script>

<style lang="scss" scoped>
	.content {
		.wrapper {
			width: 85%;
			.plates {
				display: flex;
				flex-wrap: wrap;
				width: 100%;

				.plate {
					display: flex;
					width: 25%;
					padding: 10px;
					box-sizing: border-box;
					position: relative;
					margin-bottom: 30px;

					.plate_img {
						display: block;
						width: 100%;
						height: 100%;
						border-top-left-radius: 20px;
						border-top-right-radius: 20px;
					}

					.plate_title {
						margin-top: 15px;
					}
				}
			}
		}

		.product_title {
			font-size: 30px;
			font-weight: 600;
			margin: 20px 20px 20px 0;
		}
	}

	.footer {
		height: 200px;
		// background-color: #528c38;
	}
</style>